import React, { useEffect, useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAuth } from "../context/authContext";
import { useAccount } from "wagmi";

const WalletConnectButton = () => {
  const { handleAuth, logout, user, loading } = useAuth();
  const { address, isConnected } = useAccount();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const isMobile = useMediaQuery("(max-width:599px)");

  const buttonStyles = {
    backgroundColor: "#FFB800",
    color: "#000",
    paddingX: { xs: 1, sm: 3 },
    paddingY: { xs: 1, sm: 1.5 },
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FFB800",
      color: "#000",
    },
    "&.MuiButton-root": {
      color: "#000",
      fontFamily: "var(--font-i_r)",
    },
  };

  useEffect(() => {
    const authenticate = async () => {
      if (isConnected && address && !user && !isAuthenticating && !loading) {
        try {
          setIsAuthenticating(true);
          await handleAuth();
        } catch (error) {
          console.error("Authentication failed:", error);
        } finally {
          setIsAuthenticating(false);
        }
      }
    };

    authenticate();
  }, [isConnected, address, user, loading]);

  // Early return for admin users
  if (user?.role === "admin") {
    return (
      <Box sx={{ marginX: 1, color: "#000" }}>
        <Button onClick={logout} sx={buttonStyles}>
          Logout
        </Button>
      </Box>
    );
  }

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        const ready = mounted;
        const connected = ready && account && chain;
        let addressToDisplay = account?.ensName
          ? account?.ensName
          : account?.displayName;

        if (isMobile && addressToDisplay?.length > 7) {
          addressToDisplay = `${addressToDisplay?.slice(0, 4)} ...`;
        }

        if (!ready) return null;

        return (
          <Box sx={{ marginX: 1, color: "#000" }}>
            {(() => {
              if (!connected) {
                return (
                  <Button onClick={openConnectModal} sx={buttonStyles}>
                    Connect Wallet
                  </Button>
                );
              }

              if (chain?.unsupported) {
                return (
                  <Button onClick={openChainModal} sx={buttonStyles}>
                    Wrong network
                  </Button>
                );
              }

              if (loading || isAuthenticating) {
                return (
                  <Button disabled sx={buttonStyles}>
                    {isMobile ? "Loading..." : "Authenticating..."}
                  </Button>
                );
              }

              if (user?.address) {
                return (
                  <Button onClick={openAccountModal} sx={buttonStyles}>
                    {addressToDisplay}
                  </Button>
                );
              }

              return (
                <Button disabled sx={buttonStyles}>
                  Connecting...
                </Button>
              );
            })()}
          </Box>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default WalletConnectButton;
