// src/hooks/useRouteValidation.js
import { useState, useEffect, useRef } from 'react';

export const useRouteValidation = (validationFn, params) => {
  const [state, setState] = useState({
    isLoading: true,
    isValid: false,
    data: null,
    error: null
  });

  const abortControllerRef = useRef(null);

  useEffect(() => {
    const validateRoute = async () => {
      // Clear previous controller if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      if (!validationFn || !params) {
        setState({
          isLoading: false,
          isValid: false,
          data: null,
          error: 'Invalid parameters'
        });
        return;
      }

      setState(prev => ({ ...prev, isLoading: true }));

      try {
        const result = await validationFn(params);

        // Store new controller if returned
        if (result.controller) {
          abortControllerRef.current = result.controller;
        }

        setState({
          isLoading: false,
          isValid: result.isValid,
          data: result.data,
          error: result.error
        });
      } catch (error) {
        setState({
          isLoading: false,
          isValid: false,
          data: null,
          error: error.message || 'Validation failed'
        });
      }
    };

    validateRoute();

    // Cleanup function
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [validationFn, JSON.stringify(params)]); // Stringify params for stable dependency

  return state;
};