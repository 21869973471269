import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import ConnectWallet from "../buttons/ConnectWallet";
import { STORAGE_URL } from "../../utils";

export const PageError = ({
  message = "Sorry, the page you were looking for was not found",
  code = "404",
}) => {
  const theme = useTheme();

  const redirectHome = () => {
    window.location.href = "/";
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={STORAGE_URL + "404Img.png"}
          alt="NOT Found"
          style={{
            height: "auto",
            width: "40%",
          }}
        />
        <Box
          sx={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "--font-f_m",
              fontSize: { xl: "10vw", lg: "10vw" },
              fontWeight: "900",
              textAlign: "center",
              color: "#FFB800 !important",
            }}
          >
            {code}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font-r_r)",
              fontSize: { xl: "35px", lg: "32px" },
              fontWeight: "700",
              textAlign: "center",
              textTransform: "capitalize",
              color: "#FFF !important",
            }}
          >
            Link is Broken
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font-r_r)",
              fontSize: { xl: "25px", lg: "22px" },
              textAlign: "center",
              color: "#FFF !important",
            }}
          >
            Sorry, the page you were looking for was not found{" "}
          </Typography>
          <ConnectWallet
            label="Go Back"
            isHeader
            originHeight
            sx={{
              marginRight: "8px",
              color: "#000 !important",
              backgroundColor: theme.palette.button.default,
              mt: 2,
              borderColor: theme.palette.button.default,
              fontWeight: "800",
              "&:hover": {
                color: "white !important",
              },
            }}
            onClick={redirectHome}
          />
        </Box>
      </Box>
    </Box>
  );
};
