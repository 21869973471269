// src/components/RouteWrapper.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRouteValidation } from '../hooks/useRouteValidation';
import { PageLoader } from './common/PageLoader';
import { PageError } from './common/PageError';

const RouteWrapper = ({
  children,
  validationFn,
  loadingComponent = <PageLoader />,
  errorComponent = props => <PageError {...props} />
}) => {
  const params = useParams();
  const { isLoading, isValid, error } = useRouteValidation(validationFn, params);

  if (isLoading) {
    return loadingComponent;
  }

  if (!isValid) {
    return errorComponent({ message: error });
  }

  return children;
};

export default RouteWrapper;