import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  Box,
  ListItemText,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { selectBalance } from "../slice/balanceSlice";
import { FaTiktok, FaDiscord } from "react-icons/fa";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { useTheme } from "@emotion/react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { FaUser } from "react-icons/fa";
import connectLogo from "../assets/images/arm.png";
import LoginDialog from "./LoginDialog";
import WalletDialog from "./WalletDialog";
import ButtonGroup from "./header/ButtonGroup";
import useNavLinks from "../hooks/useNavLinks";
import WalletConnectButton from "../wallet/WalletConnectButton";
const MobileHeaderRoot = styled("div")({
  flexGrow: 1,
});

const AppBarStyled = styled(AppBar)({
  // position: "sticky",
  // top: 0,
  backgroundColor: "#000",
  height: "100px",
});

const DrawerContent = styled("div")({
  position: "sticky",
  top: "0",
  backgroundColor: "black",
  color: "white",
  width: 250,
});
const MobileHeader = ({ showBackButton = false }) => {
  const { filteredNavItems, handleLogout } = useNavLinks();
  // const location = useLocation();
  const navigate = useNavigate();

  // const isProjectsPage = location.pathname.includes("/projects");
  const [loginPopup, setLoginPopup] = useState(false);
  const [walletPopup, setWalletPopup] = useState(false);
  const [mode, setMode] = useState("login");

  // const NAVITEM = [
  //   { name: "Profile", link: "/profile" },
  //   { name: "About Us", link: "/contact" },
  //   { name: "Features", link: "/features" },
  //   {
  //     name: isProjectsPage ? "" : "custom",
  //     link: isProjectsPage ? "" : "/projects",
  //   },
  //   { name: "RoadMap", link: "/roadmap" },
  //   { name: "Tokenomiks", link: "/tokenomics" },
  //   { name: "Pricing", link: "/pricing" },
  //   { name: "FAQ", link: "/faq" },
  //   { name: "Contact", link: "/contactUs" },
  //   { name: "Logout", link: "" },
  // ];

  // const filteredNavItems = NAVITEM.filter((item) => item.name !== "");
  const { palette } = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const balance = useSelector(selectBalance);
  const path = window.location.pathname
    .split("/")
    .slice(0, window.location.pathname.split("/").length - 1)
    .join("/");
  const redirectToHome = () => {
    navigate("/");
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const NavItem = ({ to, name, onClick }) => {
    return (
      <NavLink
        to={to}
        style={{ textDecoration: "none", color: "inherit" }}
        onClick={onClick}
      >
        <ListItem button>
          <ListItemText primary={name} />
        </ListItem>
      </NavLink>
    );
  };
  const socialIcons = [
    {
      icon: <AiFillYoutube />,
      boxShadow: "1px 1px 1px 1px rgba(149, 149, 149, 0.1)",
    },
    {
      icon: <FaTiktok />,
      boxShadow: "1px 1px 1px 1px rgba(149, 149, 149, 0.1)",
    },
    {
      icon: <AiOutlineInstagram />,
      boxShadow: "1px 1px 1px 1px rgba(149, 149, 149, 0.1)",
    },
    {
      icon: <FaDiscord />,
      boxShadow: "1px 1px 1px 1px rgba(149, 149, 149, 0.1)",
    },
    {
      icon: <FaXTwitter />,
      boxShadow: "1px 1px 1px 1px rgba(149, 149, 149, 0.1)",
    },
  ];

  return (
    <MobileHeaderRoot>
      <AppBarStyled position="sticky">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            onClick={redirectToHome}
          >
            <img
              src="https://ishrostorage.blob.core.windows.net/container-3d/Logo.png"
              height="20px"
              width="auto"
              alt="inceptive studios "
            />
          </Typography>
          {/* <IconButton color="inherit" onClick={() => setWalletPopup(true)}>
            <Box
              sx={{
                borderRadius: "8px",
                width: "25px",
                height: "25px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "2px",
                background:
                  "linear-gradient(95deg, #B19513 -2.12%, #BF5C00 116.91%)",
              }}
            >
              <img
                src={connectLogo}
                height="auto"
                width="15px"
                alt="Bell Icon"
              />
            </Box>
          </IconButton> */}
          {/* <IconButton color="inherit">
            <img
              src="https://storage.googleapis.com/3d-container/Notification%20-%204.png"
              height="auto"
              width="25px"
              alt="Bell Icon"
            />
          </IconButton> */}
          <ButtonGroup />
          <WalletConnectButton />
          <IconButton color="inherit" onClick={toggleDrawer}>
            <MenuIcon
              size={25}
              style={{
                color: "white",
              }}
            />
          </IconButton>
        </Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {showBackButton ? (
            <Box
              onClick={() => navigate(path === "" ? "/" : path)}
              sx={{ margin: 1 }}
            >
              <img
                style={{ width: "12px", color: "#ffffff" }}
                src="https://ishrostorage.blob.core.windows.net/container-3d/Back Arrow.svg"
                alt="back"
              />
            </Box>
          ) : (
            <div />
          )}
        </Box>
      </AppBarStyled>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{ width: 300 }}
      >
        <DrawerContent sx={{ backgroundColor: "black", height: "100%" }}>
          <List sx={{ maxHeight: "75vh", overflowY: "auto" }}>
            {filteredNavItems.map((item, index) =>
              item.link !== "" ? (
                <NavItem
                  key={index}
                  to={item.link}
                  name={item.name}
                  onClick={toggleDrawer}
                />
              ) : (
                <NavItem
                  key={index}
                  to={""}
                  name={item.name}
                  onClick={handleLogout}
                />
              )
            )}
          </List>
          <>
            <Divider
              sx={{
                marginTop: "6vw",
                border: "1px solid #C4C4C4",
              }}
            />
            <Box
              sx={{
                marginLeft: "3vw",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "var(--font-i_r)",
                  fontSize: "18px",
                  fontWeight: "700",
                  marginBlock: "16px",
                }}
              >
                Follow Us
              </Typography>
              <Box
                display="flex"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {socialIcons.map(({ icon, boxShadow }, index) => (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: index < socialIcons.length - 1 ? "2vw" : 0,
                      boxShadow: boxShadow,
                      fontSize: "18px",
                    }}
                    width="44px"
                  >
                    {icon}
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        </DrawerContent>
      </Drawer>
      <WalletDialog walletPopup={walletPopup} setWalletPopup={setWalletPopup} />
      <LoginDialog
        loginPopup={loginPopup}
        setLoginPopup={setLoginPopup}
        mode={mode}
        setMode={setMode}
      />
    </MobileHeaderRoot>
  );
};

export default MobileHeader;
