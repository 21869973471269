import React, { useRef } from "react";
import { Box } from "@mui/system";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import ClientCard from "./ClientCard";

const HeroSliderWrapper = ({ projects = [], handleNavigate }) => {
  const { palette, ...theme } = useTheme();

  const isMobile = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("xs")
  );

  const winWidth = window.innerWidth;
  const responsiveWidth = () => {
    if (winWidth > 1920) {
      return "13vw";
    } else if (winWidth > 1500) {
      return "12.6vw";
    } else if (winWidth > 1000 && winWidth <= 1500) {
      return "15vw";
    } else if (winWidth > 700 && winWidth <= 1000) {
      return "18vw";
    } else if (winWidth > 500 && winWidth <= 700) {
      return "33vw";
    } else if (winWidth <= 500) {
      return "50vw";
    } else {
      return "10vw";
    }
  };

  const Projects = projects.map((project, i) => (
    <ClientCard
      key={i}
      onClick={() => handleNavigate(project)}
      width={responsiveWidth()}
      imgSrc={project.thumbImg}
      name={project.name}
      minWidth={responsiveWidth()}
      twitterUrl={project.x}
      status={project.status}
    />
  ));

  const scrollContainerRef = useRef(null);

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "2em",
        }}
      >
        {Projects?.length > 2 && (
          <Box>
            <IconButton onClick={() => scroll("left")} color="primary">
              <ChevronLeftIcon size={isMobile ? "12px" : "1vw"} />
            </IconButton>
            <IconButton onClick={() => scroll("right")} color="primary">
              <ChevronRightIcon size={isMobile ? "12px" : "1vw"} />
            </IconButton>
          </Box>
        )}
      </Grid>

      <Box
        ref={scrollContainerRef}
        sx={{
          display: "flex",
          width: "100%",
          overflowX: "auto",
          "::-webkit-scrollbar": {
            display: "none !important",
          },
        }}
      >
        {Projects}
      </Box>
    </Box>
  );
};

export default HeroSliderWrapper;
