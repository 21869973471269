import React, { useState } from "react";
import { Box } from "@mui/material";
import HeaderButton from "./HeaderButton";
import { selectBalance } from "../../slice/balanceSlice";
import { useSelector } from "react-redux";
import LoginDialog from "../LoginDialog";
import Withdraw from "../../pages/pricing/payment/Withdraw";
import { useAccount, useBalance } from "wagmi";

const ButtonGroup = () => {
  const balance = useSelector(selectBalance);
  const [loginPopup, setLoginPopup] = useState(false);
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  const [mode, setMode] = useState("login");

  // Get wallet connection status and address
  const { address, isConnected } = useAccount();

  // Get native APE balance from ApeChain
  const { data: apeBalance } = useBalance({
    address: address,
    chainId: 33139, // ApeChain mainnet ID
  });

  // Get native APE balance from Curtis testnet if enabled
  const { data: curtisBalance } = useBalance({
    address: address,
    chainId: 33111, // Curtis testnet ID
    enabled: process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true",
  });

  const handleLoginPopup = () => {
    setLoginPopup(true);
  };

  const handleWalletPopup = () => {
    setWithdrawPopup(true);
  };

  const handleWithdrawClose = () => {
    setWithdrawPopup(false);
  };

  const formatBalance = (balanceData) => {
    if (!balanceData) return null;
    return parseFloat(balanceData.formatted).toFixed(2);
  };

  // Get the appropriate balance based on the network
  const getCombinedApeBalance = () => {
    if (!isConnected) return null;

    // If testnet is enabled and we have a Curtis balance, show that
    if (process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true" && curtisBalance) {
      return formatBalance(curtisBalance);
    }

    // Otherwise show mainnet balance
    return formatBalance(apeBalance);
  };

  return (
    <>
      {apeBalance && (
        <div>
          <Withdraw
            show={withdrawPopup}
            onClose={handleWithdrawClose}
            title="Bridge"
          />
          <Box
            sx={{
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              padding: { xs: "4px", sm: "8px" },
              borderRadius: "4px",
              margin: "auto",
              maxWidth: "600px",
              marginRight: "8px",
            }}
          >
            {/* <HeaderButton
          onClick={handleWalletPopup}
          price={balance}
          currency="Credits"
          imgUrl="https://storage.googleapis.com/3d-container/BalanceIcon.png"
        /> */}
            <HeaderButton
              onClick={handleWalletPopup}
              price={getCombinedApeBalance()}
              currency="APE"
              imgUrl="https://ishrostorage.blob.core.windows.net/container-3d/apeCoinLogo.png"
            />
            {/* <HeaderButton
          imgUrl="https://storage.googleapis.com/3d-container/reconnect.png"
          onClick={handleLoginPopup}
          address={isConnected ? address : undefined}
        /> */}
          </Box>
          <LoginDialog
            loginPopup={loginPopup}
            setLoginPopup={setLoginPopup}
            mode={mode}
            setMode={setMode}
          />
        </div>
      )}
    </>
  );
};

export default ButtonGroup;
