import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const HomeSlider = ({ projects }) => {
  const { palette, ...theme } = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    adaptiveHeight: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    style: {
      padding: "0px !important",
      margin: "0px !important",
    },
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  return (
    <Slider className="slick-slidd" {...settings}>
      {projects.map((project, index) => (
        <Box
          key={index}
          sx={{
            padding: 0,
            margin: 0,
            height: "92vh",
            background: `linear-gradient( to bottom,rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 0.2) 10%, /* Smooth start at the top */rgba(0, 0, 0, 0) 20%,rgba(0, 0, 0, 0) 80%,rgba(0, 0, 0, 0.2) 90%, /* Smooth end at the bottom */rgba(0, 0, 0, ${
              index === currentIndex ? 1 : 0
            }) 100%)`,
            [theme.breakpoints.down("sm")]: {
              height: "70vh",
            },
            "& img": {
              position: "relative",
              height: "100%",
              width: "100%",
              objectFit: "cover",
              objectPosition: "top",
              display: "flex",
              zIndex: -10000,
            },
          }}
        >
          <img src={project?.sliderImg} alt={`project-${index}`} />
        </Box>
      ))}
    </Slider>
  );
};

export default HomeSlider;
