import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { BackButton, FooterHome, Header } from "../../components";
import { useTheme } from "@emotion/react";
import { FaXTwitter } from "react-icons/fa6";
import ConnectWallet from "../../components/buttons/ConnectWallet";
import { FaDiscord } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import MobileHeader from "../../components/MobileHeader";
import MobileFooter from "../../components/MobileFooter";
const Profile = () => {
  const { palette, ...theme } = useTheme();
  const isMobie = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("xs")
  );
  const socials = [
    {
      logo: <FaXTwitter />,
      name: "Twitter",
    },
    {
      logo: <FaDiscord />,
      name: "Discord",
    },
    {
      logo: <MdMailOutline />,
      name: "Enter E-mail",
    },
  ];
  return (
    <>
      {isMobie ? <MobileHeader /> : <Header hamburger={true} />}

      <Grid
        sx={{
          display: "flex",
          zIndex: 1000,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          // backgroundImage:
          //   "url('https://ishrostorage.blob.core.windows.net/container-3d/Group%20232-min%20(1)%20(1)%20(1).png')",
          height: { xl: "100vh", lg: "100vh", md: "90vh", sm: "60vh" },
          [theme.breakpoints.down("md")]: {
            marginTop: "10vh",
            marginBottom: "20vh",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "18%",
            left: "50px",
            zIndex: 1000,
          }}
        >
          <BackButton />
        </Box>
        <Grid
          sx={{
            border: "1px solid #ffb800",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg, rgba(234, 211, 12, 0.16) 0%, rgba(234, 211, 12, 0.00) 100%)",
          }}
          container
          xs={11}
          m="auto"
          justifyContent="center"
          alignItems="center"
          sm={11}
          md={6}
          lg={4}
          xl={4}
          height={{ xl: "60%", md: "60%", lg: "60%" }}
        >
          <Grid xs={10}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="row"
            >
              <img
                width="70px"
                height="70px"
                style={{
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
                src="https://ishrostorage.blob.core.windows.net/container-3d/MAYC%20banner.png"
                alt="profile"
              />
              <Box marginLeft="10px">
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontFamily: "var(--font-i_r)",
                    fontSize: "1vw",
                    textAlign: "left",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "14px",
                      marginRight: "0px",
                    },
                    color: "#FFF",
                  }}
                >
                  Doodler.eth
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontFamily: "var(--font-i_r)",
                    fontSize: "0.9vw",
                    textAlign: "left",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "14px",
                      marginRight: "0px",
                    },
                    color: "#C4C4C4",
                  }}
                >
                  Primary
                </Typography>
              </Box>
              <Box
                sx={{
                  ml: 3,
                  height: "65px",
                  width: "1px",
                  backgroundColor: "#C4C4C4",
                }}
              />
              <Box
                sx={{
                  height: "10px",
                  width: "10px",
                  marginInline: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#84CC16",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "400",
                  fontFamily: "var(--font-i_r)",
                  fontSize: "0.9vw",
                  textAlign: "left",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "14px",
                    marginRight: "0px",
                  },
                  color: "#C4C4C4",
                }}
              >
                Ethereum
              </Typography>
            </Grid>
            <Box
              sx={{
                my: 2,
                height: "1px",
                width: "100%",
                backgroundColor: "#C4C4C4",
              }}
            />
            <Typography
              sx={{
                fontWeight: "400",
                fontFamily: "var(--font-i_r)",
                fontSize: "0.9vw",
                textAlign: "left",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                  marginRight: "0px",
                },
                color: "#C4C4C4",
              }}
            >
              Linked Delegates to your Account
            </Typography>
            <Box
              sx={{
                my: 2,
                height: "120px",
                width: "100%",
                backgroundColor: "#2B2B2B",
                borderRadius: "8px",
                border: "1px solid rgba(149, 149, 149, 0.16)",
              }}
            ></Box>
            <Typography
              sx={{
                fontWeight: "400",
                fontFamily: "var(--font-i_r)",
                fontSize: "0.9vw",
                textAlign: "left",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                  marginRight: "0px",
                },
                color: "#C4C4C4",
              }}
            >
              Connected Accounts
            </Typography>
            {socials.map((social) => {
              return (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    my: 2,
                    p: 2,
                    height: "50px",
                    width: "100%",
                    backgroundColor: "#2B2B2B",
                    borderRadius: "8px",
                    border: "1px solid rgba(149, 149, 149, 0.16)",
                  }}
                >
                  <Box alignItems="center" display="flex">
                    <Box
                      p={1}
                      sx={{
                        backgroundColor: "#ffb800",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {social.logo}
                    </Box>
                    <Typography
                      sx={{
                        ml: 2,
                        fontWeight: "400",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "0.9vw",
                        textAlign: "left",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "14px",
                        },
                        color: "#C4C4C4",
                      }}
                    >
                      {social.name}
                    </Typography>
                  </Box>
                  <Button
                    // originHeight="30px"
                    sx={{ height: "30px" }}
                  >
                    Connect
                  </Button>
                </Box>
              );
            })}
          </Grid>
        </Grid>

        <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          {isMobie ? <MobileFooter /> : <FooterHome />}
        </Box>
      </Grid>
    </>
  );
};

export default Profile;
