import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  styled,
  Typography,
} from "@mui/material";
import Layout from "../../pages/pricing/payment/Layout";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
const Privacy = ({ show, onClose, title, checkBox }) => {
  const [isChecked, setIsChecked] = useState(false);

  const TermsContainer = styled(Container)(({ theme }) => ({
    borderRadius: "8px",
    height: "40vh",
    overflowY: "auto",
    marginLeft: 0,
    paddingLeft: 0,
  }));

  const SectionTitle = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "29.04px",
    textAlign: "left",
    color: "#fff",
    fontFamily: "Inter, sans-serif",
  }));

  const Paragraph = styled(Typography)(({ theme }) => ({
    color: "#C4C4C4",
    fontFamily: "Inter, sans-serif",
  }));

  const onChangeRecaptcha = (value) => {};
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <Layout show={show} onClose={onClose} title={title}>
      <TermsContainer maxWidth="sm">
        <SectionTitle variant="h6">1. Introduction</SectionTitle>
        <Paragraph paragraph>
          Welcome to DashBO! These Terms and Conditions govern your use of our
          platform, services, and products. By accessing or using DashBO, you
          agree to comply with and be bound by these terms. If you do not agree
          with any part of these terms, please do not use our platform.
        </Paragraph>
        <SectionTitle variant="h6">2. Eligibility</SectionTitle>
        <Paragraph paragraph>
          To use DashBO, you must be at least 18 years old or the age of
          majority in your jurisdiction. By using DashBO, you represent and
          warrant that you meet these eligibility requirements.
        </Paragraph>
        <SectionTitle variant="h6">3. Account Registration</SectionTitle>
        <Paragraph paragraph>
          - You must create an account to access certain features of DashBO. -
          You agree to provide accurate and complete information during the
          registration process and to update such information to keep it
          accurate and complete. - You are responsible for maintaining the
          confidentiality of your account credentials and for all activities
          that occur under your account.
        </Paragraph>
        <SectionTitle variant="h6">
          4. User-Generated Content (UGC)
        </SectionTitle>
        <Paragraph paragraph>
          - DashBO is a UGC platform that allows users to create, upload, and
          share content, including 3D animations, poses, face filters, AR
          experiences, and avatars. - By submitting content to DashBO, you grant
          us a non-exclusive, worldwide, royalty-free license to use, display,
          reproduce, and distribute your content on our platform and through
          other media. - You retain all ownership rights to your content.
        </Paragraph>
        <SectionTitle variant="h6">5. Community Guidelines</SectionTitle>
        <Paragraph paragraph>
          - You agree to use DashBO in a manner that is respectful and lawful. -
          You must not create, upload, or share content that is offensive,
          illegal, or infringes on the rights of others. - We reserve the right
          to remove any content that violates these guidelines or our policies.
        </Paragraph>
        <SectionTitle variant="h6">6. Intellectual Property</SectionTitle>
        <Paragraph paragraph>
          - All intellectual property rights in the DashBO platform, including
          software, designs, and trademarks, are owned by us or our licensors. -
          You may not use, reproduce, or distribute any content from DashBO
          without our prior written consent.
        </Paragraph>
        <SectionTitle variant="h6">
          7. ApeChain and ApeCoin Ecosystem
        </SectionTitle>
        <Paragraph paragraph>
          - DashBO is built on ApeChain and integrates with the ApeCoin
          ecosystem. - Transactions on DashBO may involve cryptocurrency. You
          acknowledge and accept the risks associated with cryptocurrency
          transactions, including volatility and security risks.
        </Paragraph>
        <SectionTitle variant="h6">8. Supported Collections</SectionTitle>
        <Paragraph paragraph>
          - DashBO supports content creation for Bored Ape Yacht Club (BAYC),
          Meebits, Mutant Ape Yacht Club (MAYC), Koda, CloneX, AKCB, Mocaverse,
          0n1, Azuki, and other collections. - We provide base models and tools
          for generating 3D models for collections that do not currently offer
          them.
        </Paragraph>
        <SectionTitle variant="h6">9. Limitation of Liability</SectionTitle>
        <Paragraph paragraph>
          - DashBO is provided "as is" and "as available" without any warranties
          of any kind, either express or implied. - We do not warrant that the
          platform will be uninterrupted, error-free, or free of harmful
          components. - In no event shall we be liable for any direct, indirect,
          incidental, special, or consequential damages arising out of or in
          connection with your use of DashBO.
        </Paragraph>
        <SectionTitle variant="h6">10. Termination</SectionTitle>
        <Paragraph paragraph>
          - We reserve the right to terminate or suspend your account at any
          time, with or without notice, for any reason, including violation of
          these terms. - Upon termination, you must cease all use of DashBO and
          delete any copies of content obtained through the platform.
        </Paragraph>
        <SectionTitle variant="h6">11. Changes to Terms</SectionTitle>
        <Paragraph paragraph>
          - We may update these Terms and Conditions from time to time. We will
          notify you of any changes by posting the new terms on our website. -
          Your continued use of DashBO after the changes take effect constitutes
          your acceptance of the new terms.
        </Paragraph>
        <SectionTitle variant="h6">12. Contact Us</SectionTitle>
        <Paragraph paragraph>
          If you have any questions about these Terms and Conditions, please
          contact us at support@dashbo.com.
        </Paragraph>
      </TermsContainer>
      {checkBox && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            paddingTop: "1.5vw",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary"
                sx={{
                  fontSize: "0.6vw",
                  color: "#fff",
                  paddingLeft: 3,
                  margin: 1,
                }}
              />
            }
            label="By using DashBO, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy."
            sx={{
              fontSize: "0.6vw",
              "&.Mui-checked": {
                color: "#fff",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "1.5rem",
              },
              "&.MuiCheckbox-root": {
                color: "#C4C4C4",
              },
              "& .MuiTypography-root": {
                color: "#C4C4C4",
                fontSize: "0.8vw",
              },
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              position: "relative", // Use relative positioning to contain the absolute element
            }}
          >
            <ReCAPTCHA
              sitekey="6LcD0AwqAAAAAEvOJEURkpV-LcPRTuq39AabWZhS"
              onChange={onChangeRecaptcha}
              style={{
                transform: "scale(0.8)",
                transformOrigin: "0 0",
                marginLeft: "12px",
              }} // Scale down the reCAPTCHA
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                "&:hover": { backgroundColor: "#FFB800" },
                fontWeight: "800",
                textTransform: "none",
                fontFamily: "var(--font-i_r)",
                marginRight: "10px",
              }}
            >
              Accept
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                "&:hover": { backgroundColor: "#FFB800", color: "#000" },
                color: "#fff",
                fontWeight: "800",
                border: "1px solid #FFB800",
                textTransform: "none",
                fontFamily: "var(--font-i_r)",
              }}
            >
              Decline
            </Button>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};
export default Privacy;
