import { useLocation } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { useCallback } from "react";

export default function useNavLinks() {
  const location = useLocation();
  const { logout } = useAuth();

  // Check if the URL contains "/projects"
  const isProjectsPage = location.pathname.includes("/projects");

  const handleLogout = useCallback(() => {
    console.log("Logging out...");
    logout();
  }, [logout]);

  const NAVITEM = [
    { name: "Profile", link: "/profile" },
    { name: "About Us", link: "/contact" },
    { name: "Features", link: "/features" },
    {
      name: isProjectsPage ? "" : "custom",
      link: isProjectsPage ? "" : "/projects",
    },
    { name: "RoadMap", link: "/roadmap" },
    { name: "Tokenomiks", link: "/tokenomics" },

    { name: "Pricing", link: "/pricing" },
    { name: "News", link: "/" },
    { name: "FAQ", link: "/faq" },
    { name: "Contact", link: "/contactUs" },
    { name: "Logout", link: "" },
  ];

  const filteredNavItems = NAVITEM.filter((item) => item.name !== "");

  return {
    filteredNavItems,
    handleLogout,
  };
}
