import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, useSignMessage, useAccountEffect } from "wagmi";
import instance from "../api";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { signMessageAsync } = useSignMessage();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const [isCheckingAccess, setIsCheckingAccess] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const navigate = useNavigate();
  const { address, isConnected } = useAccount();

  const handleAuth = async () => {
    if (isAuthenticating) return;

    try {
      setLoading(true);

      // Check if already authenticated or admin
      const authCheckResponse = await instance.get('/auth/authenticate');
      const userData = authCheckResponse.data.user;

      if (userData?.role === 'admin') {
        setUser(userData);
        return userData;
      }

      if (!address) {
        throw new Error('No wallet address available');
      }

      // Request message
      const messageResponse = await instance.post('/auth/requestMessage', {
        address: address,
        chain: 1
      });

      const message = messageResponse.data.message;
      if (!message) {
        throw new Error('No message received from server');
      }

      // Sign message
      const signature = await signMessageAsync({ message });

      // Verify signature
      const verifyResponse = await instance.post('/auth/verify', {
        message,
        signature,
      });

      const newUserData = verifyResponse.data;
      setUser(newUserData);

      return newUserData;
    } catch (error) {
      console.error('Authentication error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const login = async (username, password) => {
    setLoading(true);
    try {
      const response = await instance.post("/auth/signin", {
        username,
        password,
      });

      if (response.status === 200) {
        setUser(response.data);
        navigate("/official-demo");
      }
      return response;
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await instance.post('/auth/logout');
      setUser(null);
      navigate("/official-demo");
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const checkAccess = async (collection, tokenId) => {
    setIsCheckingAccess(true);
    try {
      const response = await instance.get(
        `/auth/verify-access/${collection}/${tokenId}`
      );
      const { hasAccess: newHasAccess } = response.data;
      setHasAccess(newHasAccess);
      if (!newHasAccess) navigate('/official-demo');
      return newHasAccess;
    } catch (error) {
      console.error("Access check failed:", error);
      setHasAccess(false);
      return false;
    } finally {
      setIsCheckingAccess(false);
    }
  };

  // Initial authentication check
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const { data } = await instance.get('/auth/authenticate');
        if (data.user) {
          setUser(data.user);
        }
      } catch (error) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, []); // Run only once on mount

  useAccountEffect({
    onDisconnect() {
      logout();
    },
  });

  const contextValue = {
    user,
    isConnected,
    loading,
    handleAuth,
    logout,
    login, // Added login to context value
    checkAccess,
    hasAccess,
    isCheckingAccess
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};