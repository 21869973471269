import { Box, useMediaQuery, useTheme } from "@mui/material";
import MobileFooter from "../components/MobileFooter";
import { FooterHome } from "../components";

const ComingSoon = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(/images/ComingSoon.png)`,
          backgroundSize: {
            xs: "contain",
            sm: "cover",
          },
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flex: 1 }} />
        {isMobile ? <MobileFooter /> : <FooterHome />}
      </Box>
    </div>
  );
};
export default ComingSoon;
