import { Box, Typography, useMediaQuery } from "@mui/material";
import { backSvg } from "../assets/images";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const BackButton = ({ title = "Back", customBack = false, onClick }) => {
  const { palette, ...theme } = useTheme();
  const isMobile = useMediaQuery("(max-width:599px)");
  const navigate = useNavigate();
  const path = window.location.pathname
    .split("/")
    .slice(0, window.location.pathname.split("/").length - 1)
    .join("/");
  return (
    <Box
      onClick={() =>
        customBack ? onClick() : navigate(path === "" ? "/" : path)
      }
      display="flex"
      sx={{
        cursor: "pointer",
        transition: "all .4s ease-in-out",
        ".back-button-text": {},
        "&:hover": {
          marginLeft: "2px",
          ".back-button-text": {
            color: palette.primary[100],
          },
        },
      }}
      width="4.5vw"
      alignItems="center"
      justifyContent="flex-start"
    >
      <img
        style={{ width: isMobile ? "20px" : "0.7vw", color: "#ffffff" }}
        src="https://ishrostorage.blob.core.windows.net/container-3d/Back Arrow.svg"
        alt="back"
      />
      <Typography
        className="back-button-text"
        sx={{
          fontWeight: "700",
          textTransform: "capitalize",
          fontFamily: "var(--font-f_r)",
          marginLeft: "15px",
          fontSize: "1.3vw",
          color: palette.grey[200],
          [theme.breakpoints.down("md")]: {
            fontSize: "20px",
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: "22px",
          },
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default BackButton;
