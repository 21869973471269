import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { BackButton, Header } from "../../components";
import FooterHome from "../../components/footer/FooterHome";
import "../../index.css";
import { getProjectChildrenBySlug } from "../../services/clientService";
import HomeSlider from "./HomeSlider";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import MobileFooter from "../../components/MobileFooter";
import MobileHeader from "../../components/MobileHeader";
import SliderSkeleton from "../SliderSkeleton";
import HeroSliderWrapper from "../../components/HeroSliderWrapper";

const CollectionData = () => {
  const theme = useTheme();
  const { clientId, id } = useParams();
  const [projects, setProjects] = useState([]);
  const [slug, setSlug] = useState([]);
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getData = async () => {
    try {
      const { slug, projects } = await getProjectChildrenBySlug(id);
      if (projects) {
        setProjects(projects.children);
        setSlug(slug);
      }
    } catch (err) {}
  };

  const handleNavigate = (project) => {
    navigate(`/${slug}/${project.slogan}`);
  };

  useEffect(() => {
    getData();
  }, [clientId]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      {isMobile ? <MobileHeader></MobileHeader> : <Header hamburger={true} />}
      <Box
        sx={{
          position: "absolute",
          top: "15%",
          left: "60px",
          zIndex: 1000,
        }}
      >
        <BackButton
          customBack={true}
          onClick={() => {
            navigate("/" + clientId);
          }}
        />
      </Box>
      <Box>
        {projects.length > 0 ? (
          <HomeSlider projects={projects} />
        ) : (
          <Box
            sx={{
              minHeight: "86vh", // Set a minimum height for HomeSlider when projects are empty
            }}
          >
            <Typography
              variant="h1"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#ffffff",
                padding: "155px",
              }}
            ></Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          marginLeft: isMobile ? "0px" : "20px",
          bottom: { xl: "9%", lg: "7%", md: "10%", sm: "20%", xs: "20%" },
          width: "100%",
          minHeight: "300px",
        }}
      >
        {projects.length > 0 ? (
          <HeroSliderWrapper
            projects={projects}
            handleNavigate={handleNavigate}
          />
        ) : (
          <SliderSkeleton count={isMobile ? 2 : 7} />
        )}
      </Box>
      {isMobile ? <MobileFooter></MobileFooter> : <FooterHome />}
    </Box>
  );
};

export default CollectionData;
