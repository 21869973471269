import { Suspense } from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { Web3ModalProvider } from "./wallet/WalletProvider";
import { Loader } from "./components";
import { LoaderProvider } from "./context/loaderContext";
import store from "./store";
import { AuthProvider } from "./context/authContext";
import { themeSettings } from "./theme";
import { GlobalTheme } from "./theme/GlobalTheme";
import AppRoutes from "./AppRoutes";

function App() {
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <Suspense fallback={<Loader />}>
        <Web3ModalProvider>
          <LoaderProvider>
            <ThemeProvider theme={themeSettings}>
              <GlobalTheme theme={themeSettings} />
              <SnackbarProvider
                autoHideDuration={3000}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <CssBaseline />
                <Provider store={store}>
                  <BrowserRouter>
                    <AuthProvider>
                      <AppRoutes />
                    </AuthProvider>
                  </BrowserRouter>
                </Provider>
              </SnackbarProvider>
            </ThemeProvider>
          </LoaderProvider>
        </Web3ModalProvider>
      </Suspense>
    </HelmetProvider>
  );
}

export default App;
